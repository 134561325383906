import React, { useEffect, useState, useRef, FC } from "react";
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { toast } from "react-toastify";
import { useAuth } from "../../auth/authContext";
import ReactJson from "react-json-view";
import {
  Bay,
  GetSitesAreasAndLanesQuery,
  GetTestingBaysQuery,
} from "../../gql/graphql";

const SITES = gql`
  query GetSitesAreasAndLanes {
    sites(where: { isActive: { eq: true } }, take: 100) {
      items {
        id
        name
        isActive
        company {
          id
          name
        }
      }
    }
    areas(take: 1000) {
      items {
        id
        siteId
        senseIqId
        name
        pgsEnabled
        isActive
      }
    }
    accessLanes(take: 1000) {
      items {
        id
        siteId
        senseIqId
        name
        fromAreaId
        toAreaId
        hasQrReader
        hasAnpr
      }
    }
  }
`;

const BAYS = gql`
  query GetTestingBays($siteId: UUID!) {
    bays(siteId: $siteId) {
      items {
        id
        name
        areaId
        number
        isOccupied
        isOverstay
        senseIqId
        isActive
      }
    }
  }
`;

const SENDMESSAGE = gql`
  mutation SendMessage($siteId: UUID!, $type: String!, $message: String!) {
    sendMessage(siteId: $siteId, type: $type, message: $message) {
      id
      siteId
      type
      createdUtc
      payload
      response
      sequenceId
      duration
    }
  }
`;

const CLEARSITEDATA = gql`
  mutation ClearSiteData($siteId: UUID!) {
    clearSiteData(siteId: $siteId) {
      id
    }
  }
`;

const SUBSCRIBE = gql`
  subscription BayTestingUpdated($siteId: UUID!) {
    bayUpdated(siteId: $siteId) {
      id
      name
      areaId
      number
      isOccupied
      isOverstay
      senseIqId
    }
  }
`;

export const Testing: FC<{
  qrCode?: string;
  plate?: string;
  siteId?: string;
}> = ({ qrCode, plate, siteId }) => {
  var testSite = localStorage.getItem("testSite");
  if (null === testSite || testSite === "null") {
    testSite = siteId || "00000000-0000-0000-0000-000000000000";
  }

  const { roles } = useAuth();
  const [sites, setSites] = useState<
    NonNullable<GetSitesAreasAndLanesQuery["sites"]>["items"]
  >([]);
  const [areas, setAreas] = useState<
    NonNullable<GetSitesAreasAndLanesQuery["areas"]>["items"]
  >([]);
  const [accessLanes, setAccessLanes] = useState<
    NonNullable<GetSitesAreasAndLanesQuery["accessLanes"]>["items"]
  >([]);
  const [selectedSite, setSelectedSite] = useState(testSite);
  const [sendTestMessage] = useMutation(SENDMESSAGE);
  const [clearSite] = useMutation(CLEARSITEDATA);
  const [bays, setBays] = useState<Bay[]>([]);

  // need this for subscription?
  const latestBays = useRef(bays);
  latestBays.current = bays;

  const [selectedArea, setSelectedArea] = useState<string | null>(null);
  const [selectedAccessLane, setSelectedAccessLane] = useState<string | null>(
    null
  );

  const { data } = useQuery<GetSitesAreasAndLanesQuery>(SITES, {
    fetchPolicy: "no-cache",
  });
  const { data: baysData } = useQuery<GetTestingBaysQuery>(BAYS, {
    variables: { siteId: selectedSite },
    fetchPolicy: "no-cache",
  });
  const { data: subData } = useSubscription(SUBSCRIBE, {
    variables: { siteId: selectedSite },
  });

  useEffect(() => {
    if (data && data.sites) {
      if (siteId) {
        setSites(data.sites?.items?.filter((s) => s?.id === siteId));
        setSelectedSite(siteId);
      } else {
        setSites(data.sites.items);
      }
    }
  }, [data, siteId]);

  useEffect(() => {
    if (baysData?.bays?.items) {
      setBays(
        baysData.bays.items.filter(
          (b) => b && b.areaId === selectedArea
        ) as Bay[]
      );
    } else {
      setBays([]);
    }
  }, [selectedArea, baysData]);

  useEffect(() => {
    setSelectedAccessLane(null);
    setSelectedArea(null);
    setBays([]);

    localStorage.setItem("testSite", selectedSite);

    if (data?.areas?.items && selectedSite) {
      setAreas(
        data.areas.items.filter(
          (a) => a?.siteId === selectedSite && a.isActive === true
        )
      );
    }
    if (data?.accessLanes?.items && selectedSite) {
      setAccessLanes(
        data.accessLanes.items.filter((a) => a?.siteId === selectedSite)
      );
    }
  }, [selectedSite, data]);

  useEffect(() => {
    if (subData) {
      var latest = [...latestBays.current];
      latest[latest.findIndex((x) => x.id === subData.bayUpdated.id)] =
        subData.bayUpdated;
      setBays(latest);
    }
  }, [subData]);

  const updateSiteDetails = () => {
    var structure = {
      areas: [
        {
          id: 1,
          senseIqId: 1,
          name: "default",
          parentId: null,
          capacity: 1,
          calculationType: "AreaPassages",
        },
        {
          id: 2,
          senseIqId: 2,
          name: "visitor",
          parentId: 1,
          capacity: 1,
          calculationType: "AreaPassages",
        },
        {
          id: 3,
          senseIqId: 3,
          name: "public",
          parentId: 1,
          capacity: 2,
          calculationType: "AreaPassages",
        },
      ],
      bays: [
        {
          id: 1,
          senseIqId: 1,
          buildingAreaId: 2,
          name: "ONE",
          number: 1,
          occupied: false,
          overstay: false,
          bayTypeName: "Regular",
        },
        {
          id: 2,
          senseIqId: 2,
          buildingAreaId: 3,
          name: "TWO",
          number: 2,
          occupied: false,
          overstay: false,
          bayTypeName: "EV",
        },
        {
          id: 3,
          senseIqId: 3,
          buildingAreaId: 3,
          name: "THREE",
          number: 3,
          occupied: false,
          overstay: false,
          bayTypeName: "Accessible",
        },
      ],
      accessLanes: [
        {
          id: 1,
          senseIqId: 1,
          fromBuildingAreaId: null,
          toBuildingAreaId: 1,
          name: "Entrance",
          hasQrReader: true,
          hasAnpr: true,
        },
        {
          id: 2,
          senseIqId: 2,
          fromBuildingAreaId: 1,
          toBuildingAreaId: null,
          name: "Exit",
          hasQrReader: true,
          hasAnpr: true,
        },
      ],
      bayTypes: [
        {
          bayType: 0,
          bayTypeName: "Regular",
        },
        {
          bayType: 1,
          bayTypeName: "Accessible",
        },
        {
          bayType: 18,
          bayTypeName: "EV",
        },
        {
          bayType: 19,
          bayTypeName: "EV_Accessible",
        },
        {
          bayType: 20,
          bayTypeName: "PWP",
        },
      ],
    };

    sendSiteMessage("update-site-details", JSON.stringify(structure));
  };

  const anpr = () => {
    var al = accessLanes?.find((x) => x?.id === selectedAccessLane);
    var msg = {
      accessLaneId: al?.senseIqId,
      plate,
      qrCode,
      fromAreaId: al?.fromAreaId,
      toAreaId: al?.toAreaId,
    };
    sendSiteMessage("anpr-read", JSON.stringify(msg));
  };

  const qrRead = () => {
    var al = accessLanes?.find((x) => x?.id === selectedAccessLane);
    var msg = {
      accessLaneId: al?.senseIqId,
      plate,
      qrCode,
      fromAreaId: al?.fromAreaId,
      toAreaId: al?.toAreaId,
    };
    sendSiteMessage("qr-read", JSON.stringify(msg));
  };

  const accessLaneTransit = () => {
    var al = accessLanes?.find((x) => x?.id === selectedAccessLane);
    var msg = {
      accessLaneId: al?.senseIqId,
      plate,
      qrCode,
      fromAreaId: al?.fromAreaId,
      toAreaId: al?.toAreaId,
    };
    sendSiteMessage("access-lane-transit", JSON.stringify(msg));
  };

  const toggleOccupancy = (bayId: string) => {
    var bay = bays.filter((x) => x.id === bayId)[0];
    var msg = {
      id: bay.senseIqId,
      occupied: !bay.isOccupied,
      overstay: bay.isOverstay,
    };
    sendSiteMessage("indicator-status", JSON.stringify(msg));
  };

  const sendBayAssigned = (bay: Bay) => {
    var msg = {
      id: bay.senseIqId,
      qrCode: qrCode,
      plate: plate,
    };
    sendSiteMessage("bay-assigned", JSON.stringify(msg));
  };

  const sendSiteMessage = (type: string, message: string) => {
    sendTestMessage({
      variables: { siteId: selectedSite, type: type, message: message },
    }).then((x) => {
      var content = (
        <>
          <div>{type}</div>
          <hr />
          <div>Payload:</div>
          <div>
            {x.data.sendMessage && (
              <ReactJson src={JSON.parse(x.data.sendMessage.payload)} />
            )}
          </div>
          <hr />
          <div>Response:</div>
          <div>
            {x.data.sendMessage?.response ? (
              <ReactJson src={JSON.parse(x.data.sendMessage.response)} />
            ) : (
              <ReactJson src={{}} />
            )}
          </div>
        </>
      );

      toast(content, { type: toast.TYPE.SUCCESS, autoClose: 5000 });
    });
  };

  const clearSiteData = (siteId: string) => {
    clearSite({ variables: { siteId: siteId } }).then((x) => {
      toast("Site Data cleared", { type: toast.TYPE.SUCCESS, autoClose: 3000 });
    });
  };

  return (
    <>
      {roles.includes("Parkagility") && (
        <div className="border border-danger p-3 rounded border-3 mb-5">
          <h4 className="text-danger">Testing</h4>

          {qrCode && (
            <>
              <label>QR Code</label>
              <input
                className="form-control mb-3"
                disabled={true}
                type="text"
                value={qrCode}
              ></input>
            </>
          )}

          {plate && (
            <>
              <label>Vehicle</label>
              <input
                className="form-control mb-3"
                disabled={true}
                type="text"
                value={plate}
              ></input>
            </>
          )}

          <div>
            <div>
              {selectedSite && (
                <button
                  style={{ float: "right" }}
                  className="btn btn-danger"
                  onClick={() => clearSiteData(selectedSite)}
                >
                  Clear all site data
                </button>
              )}
              <label>Sites</label>
            </div>
            {sites?.map((site) => (
              <div
                key={site?.id}
                className={
                  "btn mb-3 me-3 " +
                  (selectedSite === site?.id
                    ? "btn-secondary"
                    : "btn-outline-secondary")
                }
                onClick={() => setSelectedSite(site?.id)}
              >
                {site?.name}
              </div>
            ))}
          </div>

          <div>
            <div>
              <label>Areas</label>
            </div>
            {selectedSite && areas?.length === 0 && (
              <div className="alert alert-danger">
                No areas in selected site. Please run 'update-site-details'.
              </div>
            )}
            {areas?.map((area) => (
              <div
                className={
                  "btn me-3 mb-3 " +
                  (selectedArea === area?.id
                    ? "btn-secondary"
                    : "btn-outline-secondary")
                }
                key={area?.id}
                onClick={() => setSelectedArea(area?.id)}
              >
                {area?.name}
              </div>
            ))}
          </div>

          {(plate || qrCode) && (
            <div>
              <div>
                <label>Access Lanes</label>
              </div>
              {selectedSite && accessLanes?.length === 0 && (
                <div className="alert alert-danger">
                  No accesslanes in selected site. Please run
                  'update-site-details'.
                </div>
              )}
              {accessLanes?.map((accessLane) => (
                <div
                  className={
                    "btn me-3 mb-3 " +
                    (selectedAccessLane === accessLane?.id
                      ? "btn-secondary"
                      : "btn-outline-secondary")
                  }
                  key={accessLane?.id}
                  onClick={() => setSelectedAccessLane(accessLane?.id)}
                >
                  {accessLane?.name}
                </div>
              ))}
            </div>
          )}

          {bays && bays.length > 0 && (
            <div>
              <div>
                <label>Bays</label>{" "}
                <span className="text-info">
                  Clicking on a bay now, will send a bay-assigned message
                </span>
              </div>
              {bays.map((bay) => (
                <div
                  key={bay.id}
                  className={
                    "btn btn-sm me-1 mb-3 " +
                    (bay.isOccupied ? "btn-danger" : "btn-success")
                  }
                  // onClick={() => toggleOccupancy(bay.id)}
                  onClick={() => sendBayAssigned(bay)}
                >
                  {bay.name}
                </div>
              ))}
            </div>
          )}

          <div>
            <div>
              <label>Send Message:</label>
            </div>

            {siteId && areas?.length === 0 && (
              <>
                <div
                  className="btn btn-outline-danger me-3"
                  onClick={updateSiteDetails}
                >
                  Update Site Details (with test data)
                </div>
              </>
            )}

            {plate && selectedAccessLane && (
              <>
                <div className="btn btn-outline-danger me-3" onClick={anpr}>
                  ANPR
                </div>
              </>
            )}

            {qrCode && selectedAccessLane && (
              <>
                <div className="btn btn-outline-danger me-3" onClick={qrRead}>
                  QR Read
                </div>
              </>
            )}

            {(qrCode || plate) && selectedAccessLane && (
              <>
                <div
                  className="btn btn-outline-danger me-3"
                  onClick={accessLaneTransit}
                >
                  Access Lane Transit
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
